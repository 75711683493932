<template>
<div class="page-privacy-wrap">
    <div class="page-privacy">
        <h1>网络安全管理制度</h1>
        <h2>第一章 基本制度</h2>
        <p><i>第一条</i>使用就职在线平台应遵守国家的法律法规。</p>
        <h2>第二章 用户管理</h2>
        <p><i>第二条</i>用户不得利用平台传播以下内容：</p>
        <p>（1）违反宪法确定的基本原则的；</p>
        <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>（3）损害国家荣誉和利益的；</p>
        <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>（7）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
        <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
        <p>（10）以非法民间组织名义活动的；</p>
        <p>（11）含有法律、行政法规禁止的其他内容的。</p>
        <p><i>第三条</i>用户同时也不能利用平台传播违反其他国家法律法规的内容。</p>
        <p><i>第四条</i>平台资源上传时，应保证所上传文件的安全性，保证没有病毒和木马或其他恶意软件。</p>
        <p><i>第五条</i>用户不允许利用平台或平台服务器从事以下活动：</p>
        <p>（1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
        <p>（2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
        <p>（3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</p>
        <p>（4）故意制作、传播计算机病毒等破坏性程序的；</p>
        <p>（5）其他危害计算机信息网络安全的行为。</p>
        <p><i>第六条</i>用户不得滥用平台服务，包括但不限于：利用平台服务进行侵害他人知识产权或者合法利益的其他行为。</p>
        <h2>第三章 异常事件处理</h2>
        <p><i>第七条</i>如发现平台异常，应第一时间联系平台管理员或联系客服。异常情况包括：</p>
        <p>（1）平台无法打开；</p>
        <p>（2）部分页面出现404错误或乱码；</p>
        <p>（3）指定课程或资源无法获取；</p>
        <p>（4）账号问题，包括无法登录，购买的课程丢失；</p>
        <p>（5）购买的课程异常；</p>
        <p>（6）其他可能出现的异常。</p>
    </div>
</div>
</template>
<script>
export default {
    name: 'NetworkRegulation',
}
</script>
<style lang="stylus" scoped>
.page-privacy-wrap
    background  #fff
    padding-bottom 60px
    .page-privacy
        width 1200px
        margin 0 auto
        h1
            font-size 28px
            line-height 40px
            margin-bottom 24px
            padding-top 84px
            text-align center
        h2
            font-size 28px
            padding-top:32px;
            line-height 40px
            margin-bottom 24px
            text-align center
        p
            line-height 48px
            font-size 20px
            color #000
            text-indent:40px;
            i{
                font-weight:550;
                font-style:normal
                margin-right:10px;
            }
</style>